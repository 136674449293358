import React from "react"

const Table = (data) => {
	let rows = [];

	data.data.rows.map((row, index) => {
		let cells = [];

		row.forEach((cell) => {
			cells.push(<td>{cell}</td>)
		});

		rows.push(<tr>{cells}</tr>)
	});


	return <table>
		<thead></thead>
		<tbody>
		{rows}
		</tbody>
	</table>;

}

export default Table
