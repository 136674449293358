import { Component } from "preact/dist/preact";

import PropTypes from "prop-types"

class Link extends Component {
	onClick = (e) => {
		if (history.pushState) {
			history.pushState(null, null, this.props.href);
			window.dispatchEvent(new Event('popstate'));
		} else {
			location.hash = '#' + this.props.href;
		}

		e.preventDefault();
		if(this.props.onClick) {
			this.props.onClick(e);
		}
	}

	render({ href, className, children }) {
		return (
			<a href={href}
				className={className}
				onClick={this.onClick}>{children}</a>
		)

	}
}

Link.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string.isRequired,
	onClick: PropTypes.func
};

export default Link;