import React from "react"
import SEO from "../seo"
import PropTypes from "prop-types"

import Table from "../table"
import Image from "../image"
import Quote from '../quote';
import Video from '../video';
import Book from '../book';
import Code from '../code';
import styles from "./styles"
import Loading from '../loading';
import { Component } from 'preact';

class Article extends Component {

	constructor() {
		super();

		this.state = {
			loading: true
		};
	}

	componentDidMount() {
		this.fetch();
	}

	async componentWillReceiveProps({ pageID }) {
		if (this.props.pageID !== pageID) {
			await this.setState({
				loading: true
			});

			await this.fetch();
		}
	}

	fetch(){
		return fetch(`https://kurapov.ee/api/article/get/?id=${this.props.pageID}`)
			.then(response => response.json())
			.then(json => {
				this.setState({
					data: json.data,
					loading: false
				})
			});
	}

	render() {
		let content = [];
		let videos = [];

		if (this.state.loading) {
			return <Loading/>;
		} else {
			let data = this.state.data;

			let dataJson = data.element.content_json;

			if (dataJson) {
				dataJson.map((item, index) => {

					switch (item.type) {
						case 'text':
							content.push(<p dangerouslySetInnerHTML={{ __html: item.text }}></p>);
							break;
						case 'quote':
							content.push(<Quote data={item}/>);
							break;
						case 'book':
							content.push(<Book data={item}/>);
							break;
						case 'table':
							content.push(<Table data={item}/>);
							break;
						case 'video':
							videos.push(<Video data={item}/>);
							break;
						case 'image':
							content.push(<Image data={item}/>);
							break;
						case 'code':
							content.push(<Code data={item}/>);
							break;
					}

					if (videos.length == 2) {
						content.push(
							<div className={styles.videos}>
								{videos}
							</div>
						);
						videos = [];
					} else if (videos.length == 1 &&
						(dataJson[index + 1] && dataJson[index + 1].type !== 'video' || //last video
							dataJson.length === index + 1) //last element
					) {
						content.push(
							<div className={styles.video}>
								{videos[0]}
							</div>
						);
						videos = [];
					}
				})
			} else {
				content.push(<div dangerouslySetInnerHTML={{ __html: data.element.content }}></div>);
			}

			var addTimeFormatted = new Intl.DateTimeFormat('ru', { dateStyle: 'full', timeStyle: 'medium' }).format(new Date(data.element.date_added));

			return <div className={styles.article}>
				<SEO title={data.title}/>
				<h2>{data.title}</h2>
				<p className={styles.date}>{addTimeFormatted}</p>

				{content}
			</div>
		}
	}
}

Article.propTypes = {
	pageID: PropTypes.number.isRequired
};

export default Article
