import {Component} from "preact/dist/preact";
import PropTypes from "prop-types"
import style from './header.module.less';

import Languages from './languages';
import Link from '../link';

class Header extends Component {
	constructor() {
		super();
		this.state = {
			mobileMenuOpen: false,
			navFirstLevel: [],

			parents:    [],
			navSubMenu: []
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.pageID !== prevProps.pageID) {
			this.loadFirstLevel(this.props.langID);
			this.loadParents(this.props.pageID).then((parents)=> {
				this.setState({
					'navSubMenu': this.state.navSubMenu.slice(0, parents.length+1)
				});

				parents =  parents.reverse();
				parents.push(this.props.pageID);
				parents.map((id, i)=> {
					if (i > 1) {
						this.loadSubMenu(id, i);
					}
				})

				// this.loadSubMenu(this.props.pageID, parents.length);
			});

			this.setState({
				selectedPageId: this.props.pageID
			})
		}
	}

	loadParents(pageID) {
		var self = this;

		return fetch(`https://kurapov.ee/api/page/parents?id=${pageID}`).then(response => response.json()).then(json => {
			self.setState({
				parents: json.data,
			});

			return json.data;
		});
	}

	loadSubMenu(pageID, level) {
		var self = this;

		fetch(`https://kurapov.ee/api/page/childrenSingleLayer?id=${pageID}`).then(response => response.json()).then(json => {
			let navSubMenu    = self.state.navSubMenu;
			navSubMenu[level] = json.data;
			self.setState({navSubMenu});
			return json.data;
		});
	}

	loadFirstLevel = (langID) => {
		fetch(`https://kurapov.ee/api/page/firstLevel?lang=${langID}`).then(response => response.json()).then(json => {
			this.setState({
				navFirstLevel: json.data,
			})
		});
	}

	onClick = (page) => {
		if (history.pushState) {
			history.pushState(null, page.title, window.location.origin + page.path);
			window.dispatchEvent(new Event('popstate'));
		}
		else {
			location.hash = '#' + page.path;
		}
	}

	toggleMobileMenu = ()=>{
		this.setState({
			'mobileMenuOpen' : !this.state.mobileMenuOpen
		})
	}

	render({pageID, langID}) {

		const subMenu = [];
		if (Object.keys(this.state.navSubMenu).length) {
			Object.entries(this.state.navSubMenu).forEach(([key, row]) => {
				if (row.length) {
					subMenu.push(
						<ul  className={`level${key}`}>
							{row.map((item, index) => (
								<li className={this.state.parents.indexOf(item.ID) >=0 ? style.selected : ''}>
									<Link
										onClick={this.onClick.bind(this, item)}
										href={item.path}>{item.title}</Link>
								</li>
							))}
						</ul>
					);
				}
			});
		}

		return (
			<header className={style.header}>
				<nav id={style.menu} className={this.state.mobileMenuOpen ? '' :'closed'}>
					<div className={style.mobileMenuToggler} onClick={this.toggleMobileMenu}><img src="/assets/menu.svg" /></div>
					<ul>
						{this.state.navFirstLevel.map((item, index) => (
							<li className={this.state.parents.indexOf(item.ID) >=0 ? style.selected : ''}>
								<Link
									onClick={this.onClick.bind(this, item)}
									href={item.path}>{item.title}</Link>
							</li>
						))}
					</ul>
					<div id={style.langSwitch}>
						<Languages langID={langID}></Languages>
					</div>
				</nav>

				<nav id={style.submenu} className={this.state.mobileMenuOpen ? '':'closed'}>
					{subMenu}
				</nav>
			</header>
		)

	}
}

Header.propTypes = {
	pageID: PropTypes.number,
	langID: PropTypes.string.isRequired
};

export default Header;