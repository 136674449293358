import styles from './styles.less'
import {Component} from "preact/dist/preact";

function isNightTime() {
	var date = new Date();
	return (date.getHours() > 22 || date.getHours() < 6);
}

class NightSwitch extends Component {
	constructor() {
		super();

		let night = isNightTime();

		if (typeof window !== "undefined") {
			const userSet           = localStorage.getItem('night') !== null;
			const osPreferenceNight = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

			if (userSet) {
				night = localStorage.getItem('night') === "true";
			} else if (osPreferenceNight) {
				night = true;
			}
		}

		this.state = {
			night
		}
	}

	onClick() {
		this.setState({
			night: !this.state.night
		});

		this.props.onNightModeSet(!this.state.night);
		localStorage.setItem('night', !this.state.night);
	}


	componentDidMount() {
		this.props.onNightModeSet(this.state.night);
	}

	render({}) {
		let icon = <img src="/assets/sun.svg"/>

		if (this.state.night) {
			icon = <img src="/assets/moon.svg"/>
		}

		return <div id={styles.switch} onClick={this.onClick.bind(this)}>
			{icon}
		</div>
	}
}

export default NightSwitch