import React from "react"

const Code = (data) => {
	const code = data.data;

	if(!code){
		return null;
	}

	return <pre>
		<code>
			{code.content}
		</code>
	</pre>
}

export default Code
