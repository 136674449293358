import {Component} from "preact/dist/preact";
import styles from './styles.less'
import Link from '../../link';

class Languages extends Component {
	render({langID}) {
		return <div id={styles.languages}>
			<Link href="/eng/"
			      className={langID == 'eng' ? styles.selected : null}>EN</Link>
			<Link href="/rus/"
			      className={langID == 'rus' ? styles.selected : null}>RU</Link>
		</div>;
	}
}

export default Languages;