import { Component } from "preact/dist/preact";
import styles from './styles.less'

class Loading extends Component {
	render() {
		return <div className={styles.loader}>
			<div className={styles.bar1}></div>
			<div className={styles.bar2}></div>
			<div className={styles.bar3}></div>
			<div className={styles.bar4}></div>
			<div className={styles.bar5}></div>
			<div className={styles.bar6}></div>
		</div>
	}
}

export default Loading;