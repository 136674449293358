import React from "react"
import "./styles.less"

const Quote = (data) => {
	const quote = data.data;

	if(!quote){
		return null;
	}

	let cite = quote.citeURL ? <a href={quote.citeURL} target="_blank">{quote.cite}</a> : quote.cite;

	return <blockquote>
		<p>{quote.text}</p>
		<cite>{cite}</cite>
	</blockquote>
}

export default Quote