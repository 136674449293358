import React from "react"
import PropTypes from "prop-types"
import Link from '../link'
import styles from './styles'
import { Component } from "preact/dist/preact";
import Loading from '../loading';

class Category extends Component {

	constructor() {
		super();

		this.listenerID = null;
		const urlParams = new URLSearchParams(window.location.search);
		this.state = {
			loading: true,
			pageNr: urlParams.get('page') ? urlParams.get('page') : 1
		};
	}

	async fetch() {
		return fetch(`https://kurapov.ee/api/category/get/?id=${this.props.pageID}&page=${this.state.pageNr}`)
			.then(response => response.json())
			.then(json => {
				this.setState({
					data: json.data,
					loading: false
				})
			});
	}

	componentDidMount() {
		this.fetch();
	}

	async componentDidUpdate({ pageID }) {
		if (this.props.pageID !== pageID) {
			await this.setState({
				pageNr: 0
			});
			await this.fetch();
		}
	}

	async nextPage() {
		await this.setState({
			loading: true,
			pageNr: this.state.pageNr + 1
		});

		await this.fetch();
		await this.setState({
			loading: false
		});
	}

	async prevPage() {
		await this.setState({
			loading: true,
			pageNr: this.state.pageNr - 1
		});
		await this.fetch();
		await this.setState({
			loading: false
		});
	}

	render() {
		let content = [];
		let prev = null;
		let next = null;
		let contentBlock = null;

		if (this.state.loading) {
			contentBlock = <Loading/>;
		} else {
			if (this.state.data) {
				this.state.data.map((item, index) => {
					content.push(
						<li><Link href={item.path}>{item.title}</Link></li>
					);
				});
			}

			contentBlock = <ul className={styles.categories}>{content}</ul>;
		}

		const currentUrl = window.location.pathname;
		if (this.state.pageNr > 1) {
			prev = <Link
				onClick={this.prevPage.bind(this)}
				href={`${currentUrl}?page=${this.state.pageNr - 1}`}
				className={styles.prev}>⇠</Link>;
		}

		next = <Link
			onClick={this.nextPage.bind(this)}
			href={`${currentUrl}?page=${this.state.pageNr + 1}`}
			className={styles.next}>⇢</Link>;

		return <div className={styles.categoryWrapper}>
			{prev}
			{next}
			{contentBlock}
		</div>
	}
}

Category.propTypes = {
	pageID: PropTypes.number.isRequired
};

export default Category
