// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import ImageOffline from '../imageOffline'
import style from "./footer.module.less"

const Footer = () => (
	<footer className='notPrintable' id={style.footer}>
		<div className="interfaces subsection">
			<div>
				<a href="https://kurapov.ee/rss/?lang=rus"><ImageOffline alt="rss" src="/assets/rss.svg"/> Статьи</a>
				<a rel="license" href="http://creativecommons.org/licenses/by/3.0/"><ImageOffline src="/assets/cc.svg" alt="CC"/> Creative Commons License</a>
				<a href="https://www.youtube.com/user/artkurapov/">youtube</a>
				<a href="https://www.instagram.com/tot_ra/">instagram</a>
			</div>
			<div>
				<a href="https://www.linkedin.com/in/kurapov/">linkedin</a>
				<a href="https://github.com/tot_ra">github</a>
				<a href="https://stackoverflow.com/users/158448/artjom-kurapov?tab=profile">stackoverflow</a>
				<a href="https://goodreads.com/tot_ra">goodreads</a>
			</div>
			<div>
				<a href="https://twitter.com/tot_ra">twitter</a>
				<a href="https://medium.com/@tot_ra">medium</a>
				<a href="https://www.slideshare.net/totra/presentations">slideshare</a>
				<a href="https://habr.com/ru/users/tot_ra/posts/">habr</a>
			</div>
			<div>
				<a href="https://www.facebook.com/artkurapov">facebook</a>
				<a href="https://vk.com/artkurapov">ВКонтакте</a>
				<a href="https://steamcommunity.com/id/tot_ra/">steam</a>
				<a href="https://www.twitch.tv/tot_ra/">twitch</a>

			</div>
			<div>
				<a href="https://open.spotify.com/user/1176479585">spotify</a>
				<a href="https://soundcloud.com/tot_ra">soundcloud</a>
				<a href="https://www.reddit.com/user/tot_ra/">reddit</a>
				<a href="https://tot-ra.livejournal.com/">ЖЖ</a>
				<a href="https://www.tumblr.com/blog/pro">tumblr</a>
			</div>
		</div>
	</footer>
);

export default Footer;
