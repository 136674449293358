import { Component } from 'preact';
import PropTypes from "prop-types"

class Image extends Component {
	constructor() {
		super();

		this.state = {
			visible: true
		};
		this.listener = () => {
		};
	}

	onError() {
		this.setState({
			visible: false
		});

		if (!navigator.onLine) {
			this.listener = window.addEventListener('online', () => {
				this.setState({
					visible: true
				});
			});
		}
	}

	componentWillUnmount() {
		this.listener();
	}

	render() {
		let image = null;

		if (this.state.visible) {
			image = <img id={this.props.id} src={this.props.src} onError={this.onError.bind(this)}></img>;
		}
		return image;
	}
}

Image.propTypes = {
	id: PropTypes.string,
	src: PropTypes.string.isRequired
};


export default Image
