import {h, Component} from 'preact';
// import { Router } from 'preact-router';

import PageRouter from './PageRouter';
import Article from './article';
import Category from './category';
import Helmet from "preact-helmet";


import Header from "./header/header"
import About from "./about"
import Footer from "./footer/footer"
import "./reset.css"
import "./fonts.less"
import style from "./app.module.less"


export default class App extends Component {

	constructor() {
		super();

		this.listenerID = null;
		this.state      = {
			langID: this.getLanguage(),
			page:   {
				ID: null
			}
		};
	}

	getLanguage() {
		let langID = 'rus';
		if (typeof window !== "undefined") {
			const urlLang = window.location.pathname.substr(1, 3);

			if(['eng', 'rus'].indexOf(urlLang) >= 0){
				langID = urlLang;
			}
		}
		return langID;
	}

	componentDidMount() {
		let self        = this;
		this.listenerID = window.addEventListener('popstate', function () {
			self.setState({
				langID: self.getLanguage(),
			})
		});
	}

	componentDidUnmount() {
		this.listenerID();
	}

	onPageResolve = (page)=> {
		this.setState({
			page
		});
	}

	onNightModeSet = (nightMode)=>{
		this.setState({
			nightMode
		})
	}

	render() {

		let page = null;
		if (this.state.page.ID) {
			switch (this.state.page.module) {
				case 'article':
					page = <Article pageID={this.state.page.ID}/>
					break;
				case 'category':
					page = <Category pageID={this.state.page.ID}/>
					break;
			}
		}

		const nightMode = this.state.nightMode ? 'night' : 'day';

		return (
			<div className={nightMode}>
				<PageRouter onPageResolve={this.onPageResolve} langID={this.state.langID}>
					<Helmet title={this.state.page.title} />
					<link
						href="https://fonts.googleapis.com/css?family=Arimo:400,700|Alegreya+Sans|Open+Sans+Condensed:300|PT+Mono|Roboto+Slab:700&amp;subset=cyrillic"
						rel="stylesheet"/>
					<div id={style.layout}>
						<About onNightModeSet={this.onNightModeSet}/>

						<div id={style.right_column}>
							<Header langID={this.state.langID}
							        pageID={this.state.page.ID}/>
							<main>
								{page}
							</main>
							<Footer />
						</div>
					</div>
				</PageRouter>
			</div>
		);
	}
}