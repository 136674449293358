import Link from '../link';
import style from "./logo.module.less"
import NightSwitch from '../nightSwitch';
import ImageOffline from '../imageOffline';

import { Component } from 'preact';

class About extends Component {
	render({ onNightModeSet }) {

		return <div id={style.about}>
			<Link className={style.logo} href="/">
				<ImageOffline src={'https://kurapov.ee/img/logo.jpg'}></ImageOffline>
			</Link>

			<div id={style.contact} className="vcard">
				<h1>Артём Курапов</h1>
				<p>Software engineer в <a href="https://www.pipedrive.com/" target="_blank">Pipedrive</a></p>
				<p>Founder и пчеловод в <a href="https://gratheon.com/" target="_blank">Gratheon</a></p>
			</div>

			<NightSwitch onNightModeSet={onNightModeSet}/>
		</div>
	}
}

export default About
