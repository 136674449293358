// import React from "react"
import {Component} from "preact/dist/preact";
import PropTypes from "prop-types"
import styles from "./styles.less"

class Video extends Component {

	click(){
		this.setState({
			clicked: true
		})
	}

	render({data}) {

		const video = data;
		if (!video) {
			return null;
		}

		if (video.site == 'vimeo.com') {
			let url = `https://player.vimeo.com/video/${video.site_id}`;

			return <iframe className={styles.videoFrame} src={url} webkitallowfullscreen mozallowfullscreen
			               allowfullscreen></iframe>;
		}

		if (video.site == 'youtube.com') {

			if(!this.state.clicked) {
				const imgUrl = 'https://img.youtube.com/vi/' + video.site_id + '/0.jpg';

				const button = <button
					class="ytp-large-play-button ytp-button" aria-label="Смотреть">
					<svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
						<path class="ytp-large-play-button-bg"
						      d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
						      fill="#212121" fill-opacity="0.8"></path>
						<path d="M 45,24 27,14 27,34" fill="#fff"></path>
					</svg>
				</button>

				return <div onClick={this.click.bind(this)} style={{"backgroundImage": `url(${imgUrl}`}} className={styles.youtubeFake}>{button}</div>
			}

			const url = window.location.protocol + "//www.youtube.com/embed/" + video.site_id + '?&t=' + video.time + '&autoplay=1&origin=' + window.location.origin;

			return <iframe className={styles.videoFrame} type="text/html" src={url} allowfullscreen></iframe>
		}
	}
}
Video.propTypes = {
	data: PropTypes.object
};

export default Video
