/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from "prop-types"

import {Component} from "preact/dist/preact";

class PageRouter extends Component {
	constructor() {
		super();
		this.listenerID = null;
	}


	fetchCurrentPage() {
		fetch(`https://kurapov.ee/api/page/getByPath?lang=${this.props.langID}&path=${window.location.pathname}`).then(response => response.json()).then(json => {
			this.props.onPageResolve(json.data);
		});
	}

	componentDidMount() {
		let self = this;
		this.listenerID = window.addEventListener('popstate', function (e) {
			self.fetchCurrentPage();
		});

		this.fetchCurrentPage();
	}

	componentDidUnmount() {
		this.listenerID();
	}

	render({children}) {
		return (
			<div>
				{children}
			</div>
		)
	}
}
export default PageRouter

PageRouter.propTypes = {
	langID:        PropTypes.string.isRequired,
	onPageResolve: PropTypes.func.isRequired
}