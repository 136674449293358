import React from "react"
import styles from "./styles.less";

const Book = (data) => {
	const book = data.data;

	if(!book){
		return null;
	}

	let image = book.imageURL ? <img src={book.imageURL} /> : null;
	let link = book.targetURL ? <a href={book.targetURL} className="title" target="_blank">{book.title}</a> : book.title;
	let authors = <div className="authors">{book.authors}</div>;

	return <div className={styles.book}>
		{image}
		{link}
		{authors}

		<div className="publisher">
			{book.publisher}
			{book.year}
		</div>
	</div>
}

export default Book
