import React from "react"
import { Component } from 'preact';
import styles from './styles';

class Image extends Component {

	constructor() {
		super();

		this.state = {
			highResImageLoaded: false
		};
	}

	render(data) {
		const image = data.data;
		let imgSmall = null;

		if (!image) {
			return null;
		}

		let ratio = 100 * image.height / image.width;
		if (!image.sizes) {
			let sources = [];
			sources.push(
				<source srcset={image.thumbURL} media={`(max-width: 200px)`}/>
			)

			// if(!this.state.highResImageLoaded){
			imgSmall = <img className={styles.small} srcSet={image.thumbURL}/>
			// }

			// style={{paddingBottom:`${ratio}%`}}
			return <div
				className={styles.placeholder}>
				{imgSmall}

				<picture className={ this.state.highResImageLoaded ? styles.loaded : null }>
					{sources}
					<img srcset={image.originalURL}
						alt={image.filename}
						onLoad={() => {
							this.setState({ highResImageLoaded: true });
						}}
					/>
				</picture>
			</div>
		}

		let sources = [];
		Object.keys(image.sizes).forEach((key) => {
			if (!imgSmall) {
				imgSmall = <img className={styles.small} srcSet={image.thumbURL}/>
			}
			sources.push(
				<source srcset={image.sizes[key]} media={`(max-width: ${key}px)`}/>
			)
		});

		// style={{paddingBottom:`${ratio}%`}}
		return <div
			className={styles.placeholder}>
			{imgSmall}
			<picture className={ this.state.highResImageLoaded ? styles.loaded : null }>
				{sources}
				<img srcset={image.originalURL}
					alt={image.filename}
					onLoad={() => {
						this.setState({ highResImageLoaded: true });
					}}
				/>
			</picture>
		</div>
	}
}

export default Image
